<template>
    <tr @click="openEditLine(index)">
        <td class="pl-10">
            <div class="col">
                <strong>
                    <h6>{{ item.name }}</h6>
                </strong>
                {{ item.description }}
            </div>
        </td>
        <td class="text-center">{{ item.service_resource_name }}</td>
        <td class="text-center">{{ item.service_resource_desc_name }}</td>
        <td class="text-center">{{ item.service_resource_price }}</td>
        <td class="text-center">{{ item.quantity }}</td>
        <td class="text-center">{{ item.prev_return_qty }}</td>
        <td v-if="item.isEdit">
            <div class="td-inline">
                <input
                  v-model.trim="item.return_qty"
                  type="number" class="form-control"
                  placeholder="Qty" :min="0"
                >
            </div>
        </td>
        <td class="text-center" v-else >{{ item.return_qty }}</td>
        <td class="text-center">{{ item.rate }}</td>
        <td class="text-center">{{ item.amount }}</td>
        <td class="text-center">{{ item.discount_percent }}%</td>
        <td class="text-center">{{ item.discount_amount }}</td>
        <td class="text-center">{{ amountAfterDiscount }}</td>
        <td class="text-center">{{ item.vat }}%</td>
        <td class="text-center">{{ item.vat_amount }}</td>
        <td class="text-center">{{ totalAmount }}</td>
        <td class="text-center">{{ item.return_amount }}</td>
        <td v-if="! fromReturn">
            <div>
                <button @click="deleteItem(index)" class="btn btn-sm btn-dlt">
                    <i class="fas fa-trash"></i>
                </button>
            </div>
        </td>
    </tr>

</template>
<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handlePurchase from "@/services/modules/purchase";
import handleInventory from "@/services/modules/inventory";
import {useRoute} from "vue-router";
import handleContact from "@/services/modules/contact";
import handleHospital from "@/services/modules/hospital";

const props = defineProps({
  item: Object,
  index: Number,
  onOpenEditItem: Function,
  onDeleteItem: Function,
  vatRate: Array,
  fromReturn: {
    type: Boolean,
    default: false
  }

})
const emit = defineEmits(['onOpenEditItem', 'onDeleteItem']);
const {getProduct} = handleInventory()
const {fetchServiceResource} = handleHospital();
const $route = useRoute();
const showError = inject('showError')

const companyId = $route.params.companyId
const serviceResourceDescriptions = ref([]);

const openEditLine = (i) => {
    emit('onOpenEditItem', i)
}

const deleteItem = (i) => {
    emit('onDeleteItem', i)
}

const amount = computed(() => props.item.amount = props.item.rate * props.item.quantity)

const discountPercent = computed(() => props.item.discount_percent);

const amountAfterDiscount = computed(() => {
    const discountAmount = props.item.discount_amount === '' ? 0 : parseFloat(props.item.discount_amount)
    if(discountAmount === 0) {
        return amount.value;
    }
    return amount.value - discountAmount
})

const vatAmount = computed(() => {
    if(props.item.vat === 0) {
        return 0
    }

    if(props.item.vat) {
        return (props.item.vat/100 * amountAfterDiscount.value).toFixed(2)
    }
    return (props.item.vat/100 * amountAfterDiscount.value).toFixed(2);
})

const productId = computed(() => props.item.product_id)
const serviceResourceId = computed(() => props.item.service_resource_id)
const serviceResourceDescId = computed(() => props.item.resource_description_id)

const totalAmount = computed(() => {
    if(props.item.vat == 0) {
        return props.item.total_amount = amountAfterDiscount.value
    }
    return props.item.total_amount = parseInt(amountAfterDiscount.value) + parseInt(props.item.vat_amount)
})

const returnQty = computed(() => props.item.return_qty ?? 0);

const getAmount = (index) => {
  if (props.item[index] && props.item[index] > 0) {
    const amount = (props.item[index]/props.item.quantity) * props.item.return_qty;
    props.item[`return_${index}`] = amount;
    return amount;
  }
  return 0;
}

watch(returnQty, (newValue, oldValue) => {
  if (newValue && newValue > 0) {
    const discountAmount = getAmount('discount_amount');
    const vatAmount = getAmount('vat_amount');
    const quantity = props.item.quantity;
    const prevReturnQty = props.item.prev_return_qty ?? 0;
    const remainingQty = (quantity - prevReturnQty);
    if(newValue > remainingQty) {
      showError("Return quantity can not be more than order quantity")
      props.item.return_qty = remainingQty;
      return;
    }

    props.item.return_amount = ((newValue * props.item.rate) - discountAmount + vatAmount)
    return;
  }
  props.item.return_amount = 0;
})

watch(vatAmount, (newValue, oldValue) => {
    if(! isNaN(oldValue)) {
        props.item.vat_amount  = newValue;
    }
})

watch(productId, async () => {
    const productResp = await getProduct({
        id: productId.value,
        company_id: companyId
    })
    if (!productResp.data) {
        showError("Something went wrong please try again");
        return;
    }
    let selected = productResp.data;
    props.item.id = selected.id;
    props.item.rate = selected.description.sales_price;
    props.item.amount = props.item.quantity * props.item.rate;
    props.item.name = selected.name;
    props.item.vat = selected.description.vat_rate;
    props.item.description = selected.description.unit.description;
    props.item.service_resource = null
    props.item.service_resource_id = null
    props.item.service_resource_name = null
    props.item.resource_description_id = null;
    props.item.service_resource_name = null;
    props.item.service_resource_price = null;
    props.item.cost_of_sales_account_head_id = null;
    let product = {
        name: selected.name
    }
    props.item.product = product;
})

watch(discountPercent, (value) => {
    let discount = 0;
    value = parseFloat(value);
    if(value && value > 0 && amount.value > 0) {
        discount = amount.value * (value/100);
    }
    props.item.discount_percent = value;
    props.item.discount_amount = discount.toFixed(2);
})

onMounted(() => {
  if (serviceResourceId.value) {
    const companyQuery = `?company_id=${companyId}`;
    props.item.service_resource_id = serviceResourceId.value
    fetchServiceResource(serviceResourceId.value, companyQuery).then(res => {
      if (res.data) {
        serviceResourceDescriptions.value = res.data.resource_descriptions.map(rd => {
          return {
            ...rd,
            name: rd.service_name + (rd.service_specification ? ` (${rd.service_specification})` : "")
          }
        })
        if (serviceResourceDescId.value) {
          const description = serviceResourceDescriptions.value.find(srd => srd.id === serviceResourceDescId.value);
          props.item.service_resource_desc_name = description.name;
          props.item.service_resource_price = description.cost_of_sales_price;
          props.item.cost_of_sales_account_head_id = description.cost_of_sales_account_head_id;
        }
      }
    })
  }
})

</script>
<style scoped>
.btn-dlt{
    color: #ea5455;
}
.tr-edit td{
    min-width: 80px;
}
</style>